import { MouseEvent, KeyboardEvent, FocusEvent } from 'react';
import SV from 'server-vars';
import { getGtmEventQueue } from './gtmEventQueue';
import gtmDataLayer from './gtmDataLayer';
import { isEmpty, mergeAdditionalData } from './utils';
import { eventTypeConstants, eventNameConstants } from './constants';

import { TrackingObject } from './types';

import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

const acceptableEventProperties = [
    'moduleLocation',
    'itemId',
    'geoTest1',
    'method',
    'orderId',
    'buyerShippingCountry',
] as const;

type CustomEventProperty = ArrayElement<typeof acceptableEventProperties>;

const VALID_INTERACTIVE_EVENT_TYPES = ['click', 'submit', 'keydown', 'change']; // will add more events as updates are made to VERTICALS-901
const VALID_INTERACTIVE_KEYBOARD_KEYS = ['enter'];

const getCustomProperties = (trackObj: TrackingObject): ObjectType => {
    const customProperties = Object.keys(trackObj).reduce((acc, curr) => {
        if (acceptableEventProperties.includes(curr as CustomEventProperty)) {
            acc[curr] = trackObj[curr as CustomEventProperty];
        }
        return acc;
    }, {} as ObjectType);

    return customProperties;
};

/**
 * Event tracking using Google Tag Manager. Use the immediate option for events that need to fire prior to the initial pageview
 * @param {object} trackObj
 * @param {string} trackObj.category                The tracking category - a way to group objects to track
 * @param {string} trackObj.action                  The tracking action - typically the type of event or interaction
 * @param {string} [trackObj.label]                 The tracking label - additional dimension to use for tracking
 * @param {number} [trackObj.value]                 The tracking value
 * @param {number} [trackObj.isInteractiveEvent]    Sets noninteraction flag
 *                                                      - remove noninteraction flag during cleanup task VERTICALS-903
 * @param {number} [trackObj.noninteraction]        Affects bounce rate (must be explicitly passed in to trigger a page view)
 *                                                      - `1` (default) will not count as another page view which will mean if a user visits one page, interacts, and leaves, a bounce will be triggered (expected)
 *                                                      - `0` will essentially treat the interaction as a page view and not trigger a bounce if the user leaves the page
 * @param {object} [trackObj.additional]            An object with any additional key/value pairs to pass to GTM
 */

// leave event as optional until cleanup task VERTICALS-903
export function trackEvent(
    trackObj: TrackingObject,
    event?: MouseEvent | KeyboardEvent | FocusEvent | null,
    opts: { immediate: boolean } = { immediate: false }
): void {
    if (!isEmpty(trackObj)) {
        const gtmEventQueue = getGtmEventQueue();
        const customProperties = getCustomProperties(trackObj);

        trackObj.value = typeof trackObj.value !== 'undefined' ? trackObj.value : 0;

        /**
         * Order of precedence for noninteraction value
         *  1. noninteraction flag (will be removed in VERTICALS-903)
         *  2. isInteractiveEvent flag
         *  3. DOM event check
         *
         * Will default to 1 if no flag or event is provided
         */

        if (typeof trackObj.noninteraction !== 'undefined') {
            // noop
        } else if (typeof trackObj.isInteractiveEvent !== 'undefined') {
            trackObj.noninteraction = trackObj.isInteractiveEvent ? 0 : 1;
        } else {
            trackObj.noninteraction = 1;

            const eventType = event?.type || '';
            if (VALID_INTERACTIVE_EVENT_TYPES.includes(eventType)) {
                if (eventType === 'keydown') {
                    if (
                        VALID_INTERACTIVE_KEYBOARD_KEYS.includes(
                            (event as KeyboardEvent)?.key || ''
                        )
                    ) {
                        trackObj.noninteraction = 0;
                    }
                } else {
                    trackObj.noninteraction = 0;
                }
            }
        }

        const isSeller = !!SV.get('sellerData');
        let { eventName } = trackObj;
        if (isSeller && !eventName) {
            // use EVENT_SELLER_LEGACY for any seller events that do not have eventName so they're sent to GA4
            eventName = eventNameConstants.EVENT_SELLER_LEGACY;
        }

        const gtmData: TrackingObject = mergeAdditionalData(
            {
                event: eventTypeConstants.EVENT_EVENT,
                category: (trackObj.category || 'no category').toLowerCase(),
                eventCategory: (trackObj.category || 'no category').toLowerCase(),
                action: trackObj.action || 'no action',
                eventAction: trackObj.action || 'no action',
                label: trackObj.label || 'none',
                eventLabel: trackObj.label || 'none',
                value: trackObj.value,
                noninteraction: trackObj.noninteraction,

                // GA4 properties
                eventName,
                entry: trackObj.entry,
                trigger: trackObj.trigger,
                step_type: trackObj.step_type,
                step_interaction_name: trackObj.step_interaction_name,
                interaction_type: trackObj.interaction_type,
                search_term: trackObj.search_term,
                ...customProperties,
            },
            trackObj
        );

        if (opts.immediate) {
            gtmDataLayer.push(gtmData);
        } else {
            gtmEventQueue.push(gtmData);
        }
    }
}
