/**
 * number of (non iframe) page views per session before login/registration auto prompt
 */
export const VISITS_PER_SESSION_LOGIN_PROMPT = 5 as const;

/**
 * length of time before local storage values "expire" as session values
 * 4 hrs: (4 * 60 minutes x 60 seconds * 1000 milliseconds)
 */
export const PSEUDO_SESSION_EXPIRES_AT = 14400000 as const;

export const KEY_STORAGE = 'visitCount' as const;
export const KEY_PSEUDO_SESSION_STORAGE = 'pseudoSessionVisitCount' as const;
export const KEY_PRE_REGISTRATION_PAGE_VISIT = '_dibsPreRegistrationPageVisit' as const;
export const TYPE_PRE_REGISTRATION_PAGE_VISIT = 'PRE_REGISTRATION_PAGE_VISIT' as const;
export const CAP_PRE_REGISTRATION_PAGE_VISIT = 100 as const;
export const KEY_SESSION_INITIATED = 'sessionInitiated' as const;
export const KEY_SESSION_STORAGE = 'sessionVisitCount' as const;
export const KEY_REG_PROMPT_COUNT = 'regPromptCount' as const;
export const KEY_REG_PROMPT_LIMIT = 'regPromptLimit' as const;
export const KEY_SESSION_COUNT = 'session' as const;
export const KEY_LAST_REG_PROMPT_SESSION = 'lastRegPromptSession' as const;
export const REG_PROMPT_SESSION = 5 as const;
export const KEY_SINCE_REG_PROMPT = 'sinceRegPrompt' as const;
export const KEY_TOTAL_VISIT_COUNT = 'totalVisitCount' as const;
