import {
    getMasqueradeSellerId,
    getMasqueradeBuyerId,
} from 'dibs-cookie-jar/exports/masqueradeHelpers';
import { getAbTestV2 } from 'dibs-ab-tests/exports/clientAbTestV2';
import { AB_TEST_TYPES } from 'dibs-ab-tests/exports/testTypes';
import { getDeviceId } from 'dibs-cookie-jar/exports/deviceHelpers';
import { getAbOptIn } from 'dibs-cookie-jar';
import serverVars from 'server-vars';

import { QUERY_STRING_HEADER, PASS_THROUGH_PARAM_PREFIXES } from './constants';
import { CreateFetcherArgs } from '../client';

function getPassThroughParams(): Record<string, string> {
    const passThroughParams: Record<string, string> = {};

    if (typeof window !== 'undefined') {
        new URLSearchParams(window.location.search).forEach((value, key) => {
            PASS_THROUGH_PARAM_PREFIXES.forEach(prefix => {
                if (key.startsWith(prefix)) {
                    passThroughParams[key] = value;
                }
            });
        });
    }

    return passThroughParams;
}

export function getClientHeaders(
    getQueries: CreateFetcherArgs['getQueries'],
    userType: CreateFetcherArgs['userType'],
    getGraphQLNetworkContext: () => string | null
): Record<string, string> {
    const urlParams = new URLSearchParams({
        ...getPassThroughParams(),
    });

    const abOptIn = getAbOptIn(document.cookie);
    if (abOptIn) {
        urlParams.set('abtests', abOptIn);
    }

    for (const [key, val] of Object.entries(getQueries())) {
        if (val) {
            urlParams.set(key, val);
        }
    }
    const queryString = urlParams.toString();

    const headers: Record<string, string> = {
        [QUERY_STRING_HEADER]: queryString,
        referer: window.location.href,
    };

    const masqBuyerId = getMasqueradeBuyerId(document.cookie);
    const masqSellerId = getMasqueradeSellerId(document.cookie);
    if (userType === 'buyer' && masqBuyerId) {
        headers['x-dibs-masq-buyer'] = masqBuyerId;
    } else if (userType === 'seller' && masqSellerId) {
        headers['x-dibs-masq-seller'] = masqSellerId;
    }

    const deviceId = getDeviceId(document.cookie);
    if (deviceId) {
        headers['x-dibs-device-id'] = deviceId;
    }

    const contextName = getGraphQLNetworkContext();
    if (contextName) {
        headers['x-dibs-graphql-network-context'] = contextName;
    }

    const deviceType = serverVars.get('settings.deviceType');
    if (deviceType) {
        headers['x-dibs-device-type'] = deviceType;
    }

    const abTests = (serverVars.get('abTestsV2') || {}) as Record<string, { variant: string }>;
    const testTypes = [
        AB_TEST_TYPES.BUYER_USER,
        AB_TEST_TYPES.BUYER_CLIENT,
        AB_TEST_TYPES.BUYER_COOKIE,
    ];
    const abTestHeader = new URLSearchParams();
    for (const testName of Object.keys(abTests)) {
        const test = getAbTestV2(testName, { testTypes });
        if (test && test.variant) {
            abTestHeader.set(testName, test.variant);
        }
    }
    let abTestHeaderString = abTestHeader.toString();
    // We limit the header length because http spec says that header length should be less than 8kb
    // We limit A/B test names to [a-zA-Z0-9-_] so checking the string length gets us close enough to 2kb limit
    if (abTestHeaderString.length > 2000) {
        abTestHeaderString = 'MAX_LENGTH_EXCEEDED';
    }
    if (abTestHeaderString.length > 0) {
        headers['x-dibs-ab-tests'] = abTestHeaderString;
    }

    return headers;
}
