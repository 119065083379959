import * as storage from 'dibs-browser-storage';
import {
    VISITS_PER_SESSION_LOGIN_PROMPT,
    PSEUDO_SESSION_EXPIRES_AT,
    KEY_STORAGE,
    KEY_PSEUDO_SESSION_STORAGE,
    KEY_SESSION_INITIATED,
    KEY_SESSION_STORAGE,
    KEY_REG_PROMPT_COUNT,
    KEY_LAST_REG_PROMPT_SESSION,
    KEY_SINCE_REG_PROMPT,
} from './visitCountConstants';

export class VisitCountModel {
    constructor() {
        this.data = {
            totalVisitCount: 0,
            session: 0,
            [KEY_SINCE_REG_PROMPT]: 0,
            regPromptLimit: VISITS_PER_SESSION_LOGIN_PROMPT,
            pseudoSessionExpiration: PSEUDO_SESSION_EXPIRES_AT,
            gaSessionPageCount: 0,
            priorPagePdp: 0,
            gaTrackerEventFired: 0,
            sessionInitiated: 'true',
            sessionVisitCount: 0,
            [KEY_REG_PROMPT_COUNT]: 0,
            //Default is 1 because we can't tell exact session when the registration prompt was shown to a user. This is only relevant for old unregistered users.
            [KEY_LAST_REG_PROMPT_SESSION]: 1,
        };

        if (storage.localStorage) {
            this.parseIntStorageData(KEY_STORAGE);
            this.parseIntStorageData(KEY_PSEUDO_SESSION_STORAGE);
            this.parseIntStorageData(KEY_SESSION_STORAGE);
            this.parseRegPromptData();

            if (this.isPseudoSessionExpired()) {
                this.data = {
                    session: 0,
                    regPromptLimit: VISITS_PER_SESSION_LOGIN_PROMPT,
                    gaSessionPageCount: 0,
                    priorPagePdp: 0,
                    gaTrackerEventFired: 0,
                    sessionInitiated: 'true',
                    sessionVisitCount: 0,
                };
            }

            this.setSessionData();
        }
    }

    /**
     * checks to see if time stamp on "session" storage has expired
     * @returns {boolean}
     */
    isPseudoSessionExpired() {
        const limit = this.data.pseudoSessionExpiration;
        const now = new Date().getTime();
        // if no timestamp assume it's brand new
        return now - (this.data.pseudoTimeStamp || now) >= limit;
    }

    /**
     * fetches an object from local storage and parses its fields to integers
     * @param key {string} the key in local storage
     */
    parseIntStorageData(key) {
        const source = storage.localStorage;

        const data = source.getItem(key) || {};

        // parseInt over all fields
        Object.keys(data).forEach(field => {
            const values = parseInt(data[field], 10) || 0;
            this.set(field, values);
        });
    }

    /**
     * fetches an object from session storage and parses its fields to integers
     * @param key {string} the key in session storage
     */
    parseIntSessionData(key) {
        const data = storage.sessionStorage.getItem(key) || 0;
        this.set(key, data);
    }

    /**
     * gets value from class object
     *  @param field {string} - class object property
     *  @returns object property
     */
    get(field) {
        return this.data[field];
    }

    /**
     * sets value to class object
     * @params field {string}
     * @params value {number}
     */
    set(field, value) {
        this.data[field] = value;
    }

    /**
     * increments tracking related visitor model values
     */
    incrementTracking() {
        const incrementValues = [
            'session',
            'totalVisitCount',
            KEY_SINCE_REG_PROMPT,
            'gaSessionPageCount',
        ];
        incrementValues.forEach(value => {
            this.increment(value);
        });
    }

    setSessionData() {
        const sessionVisitCount = storage.localStorage.getItem('sessionVisitCount') || 0;
        // dibs-storage converts sessionInitiated value to boolean
        const sessionInitiated = storage.sessionStorage.getItem('sessionInitiated') || false;

        // If no ongoing session, increment sessions count, since new session initiated.
        // If no previous sessions tracked, set number of sessions to 1.
        if (!sessionInitiated || !sessionVisitCount) {
            this.set('sessionVisitCount', sessionVisitCount + 1);
        } else {
            this.set('sessionVisitCount', sessionVisitCount);
        }
    }

    parseRegPromptData() {
        let regPromptCount = parseInt(storage.localStorage.getItem(KEY_REG_PROMPT_COUNT));
        if (isNaN(regPromptCount)) {
            //Init count for users who already seen page view >= 2 login prompt. This is only relevant for old unregistered users.
            regPromptCount = this.get('session') > this.get('regPromptLimit') ? 1 : 0;
        }
        this.set(KEY_REG_PROMPT_COUNT, regPromptCount);
    }

    /**
     * increments a field by 1
     * @param field {string}
     */
    increment(field) {
        const val = this.get(field) || 0;
        this.set(field, val + 1);
    }

    /**
     * saves visit counts to local and session storage
     */
    save() {
        const localData = {
            totalVisitCount: this.get('totalVisitCount'),
            [KEY_SINCE_REG_PROMPT]: this.get(KEY_SINCE_REG_PROMPT),
            regPromptLimit: this.get('regPromptLimit'),
            [KEY_LAST_REG_PROMPT_SESSION]: this.get(KEY_LAST_REG_PROMPT_SESSION),
        };

        storage.localStorage.setItem(KEY_STORAGE, localData);

        const pseudoSessionData = {
            session: this.get('session'),
            pseudoTimeStamp: new Date().getTime(),
            gaSessionPageCount: this.get('gaSessionPageCount'),
            priorPagePdp: this.get('priorPagePdp'),
            gaTrackerEventFired: this.get('gaTrackerEventFired'),
        };

        storage.localStorage.setItem(KEY_PSEUDO_SESSION_STORAGE, pseudoSessionData);

        // FINDING-13807: Keep track of number of sessions
        storage.localStorage.setItem(KEY_SESSION_STORAGE, this.get('sessionVisitCount'));
        storage.sessionStorage.setItem(KEY_SESSION_INITIATED, this.get('sessionInitiated'));

        storage.localStorage.setItem(KEY_REG_PROMPT_COUNT, this.get(KEY_REG_PROMPT_COUNT));
    }

    /**
     * removes visit counts from session and local storage
     */
    destroy() {
        storage.localStorage.deleteItem(KEY_STORAGE);
        storage.localStorage.deleteItem(KEY_PSEUDO_SESSION_STORAGE);
    }
}
