export const CUSTOMER_TYPE_TRADE = 'trade' as const;
export const CUSTOMER_TYPE_VIP = 'vip' as const;
export const CUSTOMER_TYPE_CONSUMER = 'consumer' as const;
export const USER_TOKEN = 'userToken' as const;
export const USER_EMAIL = 'userEmail' as const;
export const USER_NAME = 'userName' as const;
export const USER_TYPE = 'userType' as const;
export const USER_EMAIL_TOKEN = 'userEmailToken' as const;
export const GUEST_USER = 'GUEST_USER' as const;
export const EVER_LOGGED_IN = 'eli' as const;
export const PASSWORD_TOKEN = 'passwordToken' as const;
export const ON_SITE_REGISTRATION = 'onSiteRegistration' as const;

// length of time before local storage values "expire" as session values
// 4 hrs: (4 * 60 minutes x 60 seconds * 1000 milliseconds)
export const PSEUDO_SESSION_EXPIRES_AT = 14400000 as const;
export const VISIT_COUNT = 'visitCount' as const;
export const REG_PROMPT_LIMIT = 2 as const;
export const PSEUDO_SESSION = 'pseudoSessionVisitCount' as const;
