import { ReactNode } from 'react';
import { TrackingObject } from 'dibs-tracking';
import { ERROR_MESSAGES } from 'dibs-widget-loader/exports/constants';
import {
    DEFAULT_FLOW,
    REGISTER,
    LOGIN,
    REQUEST_PASSWORD_EMAIL,
    RESET_PASSWORD,
    UPDATE_PASSWORD,
    SOCIAL_ACCOUNT_LINKED,
    SSO,
} from './authFlowConstants';
import {
    LAUNCH_LOGIN_MODAL,
    LAUNCH_REGISTRATION_MODAL,
    LAUNCH_REQUEST_PASSWORD_EMAIL,
    LAUNCH_RESET_PASSWORD,
    LAUNCH_UPDATE_PASSWORD,
    LAUNCH_SOCIAL_ACCOUNT_LINKED,
    LAUNCH_SSO,
    NEXT_STEP,
    AUTHENTICATION_ERROR,
    CLEAR_SERVER_ERROR,
    CLOSE_MODAL,
    SET_AUTH_RESPONSE,
    SAVE_AUTH_STATE,
    UPDATE_AUTH,
    HANDLE_SOCIAL_ERROR,
} from './authActions';
import { AdditionalPropsType, SocialAuthSuccessType, SocialAuthNameType } from './authTypes';

export type InitialState = {
    email?: string;
    fireEntryGa: boolean;
    flow: string; //TODO: should be extended with all possible flows
    ga: TrackingObject;
    modal:
        | typeof LOGIN
        | typeof REGISTER
        | typeof SOCIAL_ACCOUNT_LINKED
        | typeof UPDATE_PASSWORD
        | typeof RESET_PASSWORD
        | typeof REQUEST_PASSWORD_EMAIL
        | typeof SSO
        | '';
    modalClass?: string;
    // in case you need a callback after the auth process is done
    resolve: (arg0: { err: string; modal: string } | ObjectType) => void;
    serverError: ReactNode;
    showExistsError: boolean;
    step: number;
    additionalProps?: AdditionalPropsType;
    authResponse: {
        userId?: string;
        emailToken?: string;
        userToken?: string;
        user?: {
            profile?: {
                email?: string;
            };
            createdDate?: $TSFixMe;
            verifiedTradeStatus?: $TSFixMe;
            registrationIntent?: $TSFixMe;
        };
        accountCreated?: $TSFixMe;
        passwordAdded?: $TSFixMe;
        loggedIn?: $TSFixMe;
        socialAuthName?: SocialAuthNameType;
        socialAuthSuccessType?: SocialAuthSuccessType;
        attentiveSmsFF?: boolean;
        promotionalSmsOptinFF?: boolean;
        isSmsOptinApplicable?: boolean;
        isAppDownloadApplicable?: boolean;
    };
    isPaused: false;
    savedState: ObjectType;
    isContentOnly: boolean;
    areThirdPartyCookiesDisabled: boolean;
    onSsoLoad: ({
        serverError,
        clearServerError,
    }: {
        serverError: ReactNode;
        clearServerError: () => void;
    }) => void;
    error?: {
        message?: string | null;
    } | null;
    shouldPauseAuthFlow?: (arg0?: { step: number }) => boolean;
    onClose?: () => void;
};

export function getInitialState(): InitialState {
    return {
        email: '',
        fireEntryGa: true,
        flow: DEFAULT_FLOW,
        ga: {},
        modal: '',
        resolve() {}, // in case you need a callback after the auth process is done
        serverError: '',
        showExistsError: false,
        step: 0,
        additionalProps: undefined,
        authResponse: {},
        shouldPauseAuthFlow: () => false,
        isPaused: false,
        savedState: {},
        isContentOnly: false,
        areThirdPartyCookiesDisabled: false,
        error: null,
        onSsoLoad: () => {},
        onClose: () => {},
    };
}

type ReducerActions =
    | typeof LAUNCH_LOGIN_MODAL
    | typeof LAUNCH_REGISTRATION_MODAL
    | typeof LAUNCH_REQUEST_PASSWORD_EMAIL
    | typeof LAUNCH_RESET_PASSWORD
    | typeof LAUNCH_UPDATE_PASSWORD
    | typeof LAUNCH_SOCIAL_ACCOUNT_LINKED
    | typeof LAUNCH_SSO
    | typeof NEXT_STEP
    | typeof AUTHENTICATION_ERROR
    | typeof CLEAR_SERVER_ERROR
    | typeof CLOSE_MODAL
    | typeof SET_AUTH_RESPONSE
    | typeof SAVE_AUTH_STATE
    | typeof UPDATE_AUTH
    | typeof HANDLE_SOCIAL_ERROR;

export type ReducerAction = {
    payload?: Partial<InitialState>;
    type: ReducerActions;
};

type ReducerReturn = InitialState;
export default function authReducer(
    state = getInitialState(),
    action: ReducerAction
): ReducerReturn {
    const payload = action?.payload || {};
    const type = action?.type;
    const {
        email = state.email,
        fireEntryGa = true,
        flow = state.flow,
        ga = state.ga,
        resolve = state.resolve,
        serverError = '',
        showExistsError = false,
        step = 0,
        additionalProps = state.additionalProps,
        authResponse = state.authResponse,
        shouldPauseAuthFlow = state.shouldPauseAuthFlow,
        isPaused = state.isPaused,
        isContentOnly = state.isContentOnly,
        modalClass,
        error,
        onSsoLoad = state.onSsoLoad,
        onClose,
    } = payload;

    switch (type) {
        case LAUNCH_LOGIN_MODAL:
            return {
                ...state,
                email,
                fireEntryGa,
                flow,
                ga,
                additionalProps,
                modal: LOGIN,
                resolve,
                showExistsError,
                serverError,
                isPaused,
                shouldPauseAuthFlow,
                isContentOnly,
                authResponse,
                modalClass,
                onClose,
            };
        case LAUNCH_REGISTRATION_MODAL:
            return {
                ...state,
                email,
                fireEntryGa,
                flow,
                ga,
                additionalProps,
                modal: REGISTER,
                resolve,
                serverError,
                step,
                isPaused,
                shouldPauseAuthFlow,
                isContentOnly,
                authResponse,
                onClose,
            };
        case LAUNCH_REQUEST_PASSWORD_EMAIL:
            return {
                ...state,
                email,
                flow,
                ga,
                fireEntryGa,
                additionalProps,
                modal: REQUEST_PASSWORD_EMAIL,
                serverError,
                resolve,
                step,
                isPaused,
                shouldPauseAuthFlow,
                isContentOnly,
                authResponse,
                onClose,
            };
        case LAUNCH_RESET_PASSWORD:
            return {
                ...state,
                flow,
                fireEntryGa,
                ga,
                modal: RESET_PASSWORD,
                resolve,
                serverError,
                isContentOnly,
                authResponse,
                onClose,
            };
        case LAUNCH_UPDATE_PASSWORD:
            return {
                ...state,
                fireEntryGa,
                email,
                ga,
                modal: UPDATE_PASSWORD,
                serverError,
                isContentOnly,
                authResponse,
                onClose,
            };
        case LAUNCH_SOCIAL_ACCOUNT_LINKED:
            return { ...state, modal: SOCIAL_ACCOUNT_LINKED, onClose };
        case LAUNCH_SSO:
            return {
                ...state,
                fireEntryGa,
                flow,
                ga,
                modal: SSO,
                resolve,
                showExistsError,
                serverError,
                isContentOnly,
                authResponse,
                onSsoLoad,
                onClose,
            };
        case NEXT_STEP:
            return { ...state, email, step: step + 1, additionalProps, serverError };
        case AUTHENTICATION_ERROR:
            return { ...state, serverError };
        case CLEAR_SERVER_ERROR:
            return { ...state, serverError: getInitialState().serverError };

        case SET_AUTH_RESPONSE:
            return { ...state, authResponse, email: authResponse?.user?.profile?.email };
        case CLOSE_MODAL:
            return {
                ...getInitialState(),
                isPaused,
                areThirdPartyCookiesDisabled: state.areThirdPartyCookiesDisabled,
                savedState: state,
            };
        case SAVE_AUTH_STATE:
            return { ...state, isPaused, savedState: state };
        case UPDATE_AUTH:
            return { ...state, ...payload };
        case HANDLE_SOCIAL_ERROR:
            if (state.areThirdPartyCookiesDisabled) {
                return state;
            }
            return {
                ...state,
                areThirdPartyCookiesDisabled:
                    error?.message === ERROR_MESSAGES.THIRD_PARTY_COOKIES_DISABLED,
            };
        default:
            return state;
    }
}
