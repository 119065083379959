import { getGtmEventQueue } from './gtmEventQueue';
import { eventNameConstants } from './constants';

export const abTestBucketCustomEvent = 'e_ab_bucket' as const;

export const trackAbTestBucketEvent = ({
    seedId,
    testName,
    testVariant,
}: {
    seedId: string;
    testName: string;
    testVariant: string;
}): void => {
    getGtmEventQueue().push({
        event: abTestBucketCustomEvent,
        eventName: eventNameConstants.EVENT_AB_TEST_BUCKET,
        ab_test_seed_id: seedId,
        interaction_type: testName,
        step_interaction_name: testVariant,
    });
};
