// client code is ES6 Module
import 'text-encoding-utf-8/lib/encoding';
import { Observable, FetchFunction, GraphQLResponseWithData } from 'relay-runtime';
import fetchMultipart from 'fetch-multipart-graphql';
import { extractFiles } from 'extract-files';

import { createNetworkError } from './src/createNetworkError';
import { GRAPHQL_PATH } from './src/constants';
import { getClientHeaders } from './src/getClientHeaders';
import { GraphQLRequestBody } from './index';

export type CreateFetcherArgs = {
    getQueries: () => Record<string, string | undefined>;
    userType: 'buyer' | 'seller' | 'internal';
    getGraphQLNetworkContext?: () => string | null;
    additionalHeaders?: Record<string, string>;
};

export function createFetcher({
    getQueries = () => ({}),
    userType,
    getGraphQLNetworkContext = () => null,
    additionalHeaders = {},
}: CreateFetcherArgs): FetchFunction {
    return function fetchQuery(operation, variables) {
        return Observable.create(sink => {
            let body;
            const headers = {
                ...getClientHeaders(getQueries, userType, getGraphQLNetworkContext),
                ...additionalHeaders,
            };
            const operations: GraphQLRequestBody = { variables };
            if (operation.id) {
                // persisted query support
                operations.id = operation.id;
            } else {
                operations.query = operation.text;
            }

            const {
                clone: extractedOperations,
                files,
            }: {
                clone: GraphQLRequestBody;
                files: Map<File, string>;
            } = extractFiles(operations);

            if (files.size) {
                if (!window.FormData) {
                    throw new Error('Uploading files without `FormData` not supported.');
                }

                const formData = new FormData();
                formData.append('operations', JSON.stringify(extractedOperations));

                const pathMap: Record<string, string> = {};
                let i = 0;
                files.forEach(paths => {
                    pathMap[(++i).toString()] = paths;
                });
                formData.append('map', JSON.stringify(pathMap));

                // using a separate forEach here to properly order multipart fields
                // files should follow 'map', set in the loop above
                i = 0;
                files.forEach((paths, file) => {
                    formData.append((++i).toString(), file, file.name);
                });

                body = formData;
            } else {
                headers['Content-Type'] = 'application/json';
                body = JSON.stringify(operations);
            }

            fetchMultipart(GRAPHQL_PATH, {
                method: 'post',
                headers,
                credentials: 'same-origin', // to ensure cookies send correctly see https://github.com/github/fetch#caveats
                body,
                onNext: (
                    payloads: ReadonlyArray<GraphQLResponseWithData>,
                    meta?: { responseHeaders?: Headers }
                ) => {
                    let hasErrors = false;
                    for (const payload of payloads) {
                        if (payload.errors) {
                            hasErrors = true;
                            // @ts-ignore
                            if (window.newrelic) {
                                // @ts-ignore
                                payload.errors.forEach(e => window.newrelic.noticeError(e));
                            }
                            // all errors are critical, throw so relay treats as an error
                            const error = createNetworkError(
                                operation,
                                payload,
                                meta?.responseHeaders
                            );
                            sink.error(error);
                        }
                    }
                    if (!hasErrors) {
                        sink.next(payloads);
                    }
                },
                onError: (err: Error) => sink.error(err),
                onComplete: () => sink.complete(),
            });
        });
    };
}
